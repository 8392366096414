import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/stories`;

const getInfo = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const aboutUsService = {
  getInfo,
};

export default aboutUsService;
