import React, { Suspense } from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import "./index.css";
import "./assets/css/global.css";

import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { PopupProvider } from "./context/popUpContext";
import { HelmetProvider } from "react-helmet-async";

const Main = () => {
  return (
    <React.StrictMode>
      <Suspense fallback={<></>}>
        <Provider store={store}>
          <PopupProvider>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </PopupProvider>
        </Provider>
      </Suspense>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");

if (process.env.NODE_ENV === "production") {
  hydrateRoot(rootElement, <Main />);
} else {
  const root = createRoot(rootElement);
  root.render(<Main />);
}
