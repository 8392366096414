import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/testimonials`;

const getTestimonials = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const getAwards = async () => {
  const response = await axios.get(API_URL + "/awards");
  return response.data;
};

const testimonialService = {
  getTestimonials,
  getAwards,
};

export default testimonialService;
