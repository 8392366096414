import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/values`;

const getValues = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const valueService = {
  getValues,
};

export default valueService;
