import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/markets`;

const getList = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const getMarket = async (slug) => {
  const response = await axios.get(API_URL + `/${slug}`);
  return response.data;
};

const marketService = {
  getList,
  getMarket,
};

export default marketService;
