import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/newsletter`;

const newsLetterSubmit = async (data) => {
  const response = await axios.post(API_URL, data);
  return response.data;
};

const newsLetterService = {
  newsLetterSubmit,
};

export default newsLetterService;
