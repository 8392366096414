import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PopUpContext } from "./context/popUpContext";
import ComingSoon from "./pages/ComingSoon";
import Whatsapp from "./components/Whatsapp";
import { useSelector } from "react-redux";

const CookieConsentBanner = React.lazy(() =>
  import("./pages/CookieConsentBanner")
);

// const Home = lazy(() => import('./pages/Home'));
// const Navbar = lazy(() => import('./layouts/Navbar'));
// const Footer = lazy(() => import('./layouts/Footer'));
// const PopUp = lazy(() => import('./components/PopUp'));
// const Solutions = lazy(() => import('./pages/Solutions'));
// const Insights = lazy(() => import('./pages/Insights'));
// const Careers = lazy(() => import('./pages/Careers'));
// const Policies = lazy(() => import('./pages/Policies'));
// const OurTeam = lazy(() => import('./pages/OurTeam'));
// const SingleMember = lazy(() => import('./pages/SingleMember'));
// const Testimonial = lazy(() => import('./pages/Testimonial'));
// const OurValues = lazy(() => import('./pages/OurValues'));
// const Market = lazy(() => import('./pages/Market'));
// const Projects = lazy(() => import('./pages/Projects'));
// const InnerInsight = lazy(() => import('./pages/InnerInsight'));
// const InnerProject = lazy(() => import('./pages/InnerProject'));
// const Sustainability = lazy(() => import('./pages/Sustainability'));
// const OurStory = lazy(() => import('./pages/OurStory'));
// const ProductApplication = lazy(() => import('./pages/ProductApplication'));
// const Error = lazy(() => import('./pages/Error'));
//import Home from './pages/Home';
//import Navbar from './layouts/Navbar';
//import Footer from './layouts/Footer';
//import PopUp from './components/PopUp';
//import Solutions from './pages/Solutions';
//import Insights from './pages/Insights';
//import Careers from './pages/Careers';
//import Policies from './pages/Policies';
//import OurTeam from './pages/OurTeam';
//import SingleMember from './pages/SingleMember';
//import Testimonial from './pages/Testimonial';
//import OurValues from './pages/OurValues';
//import Market from './pages/Market';
//import Projects from './pages/Projects';
//import InnerInsight from './pages/InnerInsight';
//import InnerProject from './pages/InnerProject';
//import Sustainability from './pages/Sustainability';
//import OurStory from './pages/OurStory';
//import ProductApplication from './pages/ProductApplication';
//import Error from './pages/Error';
//import PrivacyPolicy from './pages/PrivacyPolicy';
//import Maintenance from './components/Maintenance';

// Lazy loading components
const Home = React.lazy(() => import("./pages/Home"));
const Navbar = React.lazy(() => import("./layouts/Navbar"));
const Footer = React.lazy(() => import("./layouts/Footer"));
const PopUp = React.lazy(() => import("./components/PopUp"));
const Solutions = React.lazy(() => import("./pages/Solutions"));
const Insights = React.lazy(() => import("./pages/Insights"));
const Careers = React.lazy(() => import("./pages/Careers"));
const Policies = React.lazy(() => import("./pages/Policies"));
const OurTeam = React.lazy(() => import("./pages/OurTeam"));
const SingleMember = React.lazy(() => import("./pages/SingleMember"));
const Testimonial = React.lazy(() => import("./pages/Testimonial"));
const OurValues = React.lazy(() => import("./pages/OurValues"));
const Market = React.lazy(() => import("./pages/Market"));
const Projects = React.lazy(() => import("./pages/Projects"));
const InnerInsight = React.lazy(() => import("./pages/InnerInsight"));
const InnerProject = React.lazy(() => import("./pages/InnerProject"));
const Sustainability = React.lazy(() => import("./pages/Sustainability"));
const OurStory = React.lazy(() => import("./pages/OurStory"));
const ProductApplication = React.lazy(() =>
  import("./pages/ProductApplication")
);
const Error = React.lazy(() => import("./pages/Error"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Maintenance = React.lazy(() => import("./components/Maintenance"));

function App() {
  const { popUp } = useContext(PopUpContext);
  const { isServerError } = useSelector((state) => state.error500);

  console.log(isServerError, "serverrrr");

  if (isServerError) {
    return <Maintenance />;
  }

  return (
    <>
      <Router>
        <>
          <Navbar />
          <PopUp />
          <Whatsapp />
        </>
        <CookieConsentBanner />
        <div className={`App popUp-container ${popUp ? "active" : ""}`}>
          <Routes>
            <>
              <Route element={<Home />} path={"/"} />
              <Route
                element={<Solutions />}
                path={"/solutions/PPA-power-purchase-agreement"}
              />
              <Route element={<Insights />} path={"/insights"} />
              <Route element={<Careers />} path={"/careers"} />
              <Route element={<Policies />} path={"/policies"} />
              <Route element={<OurTeam />} path={"/teams"} />
              <Route element={<PrivacyPolicy />} path={"/privacy-policy"} />
              <Route element={<SingleMember />} path={"/teams/:slug"} />
              <Route element={<Testimonial />} path={"/testimonials"} />
              <Route element={<OurValues />} path={"/our-values"} />
              <Route element={<Market />} path={"/markets/:slug"} />
              <Route element={<Projects />} path={"/projects"} />
              <Route element={<InnerInsight />} path={"/insights/:slug"} />
              <Route element={<InnerProject />} path={"/projects/:slug"} />
              <Route element={<Sustainability />} path={"/sustainability"} />
              <Route element={<OurStory />} path={"/our-story"} />
              <Route
                element={<ProductApplication />}
                path={"/solutions/:slug"}
              />
              <Route element={<Error />} path="/*" />
            </>
          </Routes>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
