import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/sustainabilities`;

const getAllSustainabilities = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const sustainabilityService = {
  getAllSustainabilities,
};

export default sustainabilityService;
