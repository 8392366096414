import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import testimonialService from "./testimonialService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  testimonials: [],
  awards: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getTestimonials = createAsyncThunk(
  "testimonials/getAll",
  async (_, thunkApi) => {
    try {
      return await testimonialService.getTestimonials();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (
        error.response &&
        (error.response.status === 500 || error.response.status === 503)
      ) {
        thunkApi.dispatch(setServerError(true));
      }

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getAwards = createAsyncThunk(
  "testimonials/getAwards",
  async (_, thunkApi) => {
    try {
      return await testimonialService.getAwards();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response && error.response.status === 500) {
        thunkApi.dispatch(setServerError(true));
      }

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestimonials.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.testimonials = action.payload;
      })
      .addCase(getAwards.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.awards = action.payload;
      });
  },
});

export const { reset } = testimonialSlice.actions;
export default testimonialSlice.reducer;
