import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/teams`;

const getTeams = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const getMember = async (slug) => {
  const response = await axios.get(API_URL + `/${slug}`);
  return response.data;
};

const teamService = {
  getTeams,
  getMember,
};

export default teamService;
