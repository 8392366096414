import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isServerError: false,
};

const Error500Slice = createSlice({
  name: "error500",
  initialState,
  reducers: {
    setServerError: (state, action) => {
      state.isServerError = action.payload;
    },
    resetServerError: (state) => {
      state.isServerError = false;
    },
  },
});

export const { setServerError, resetServerError } = Error500Slice.actions;
export default Error500Slice.reducer;
