import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import socialService from "./socialService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  socials: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getSocial = createAsyncThunk(
  "socials/getSocial",
  async (_, thunkApi) => {
    try {
      return await socialService.getSocial();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (
        error.response &&
        (error.response.status === 500 || error.response.status === 503)
      ) {
        thunkApi.dispatch(setServerError(true));
      }

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const socialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSocial.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.socials = action.payload;
    });
  },
});

export const { reset } = socialSlice.actions;
export default socialSlice.reducer;
