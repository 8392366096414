import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import valueService from "./valueService";
import { setServerError } from "../error500/error500Slice";

const initialState = {
  values: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getValues = createAsyncThunk(
  "values/getAllValues",
  async (_, thunkApi) => {
    try {
      return await valueService.getValues();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (
        error.response &&
        (error.response.status === 500 || error.response.status === 503)
      ) {
        thunkApi.dispatch(setServerError(true));
      }

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const valueSlice = createSlice({
  name: "value",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getValues.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.values = action.payload;
    });
  },
});

export const { reset } = valueSlice.actions;
export default valueSlice.reducer;
