import React from "react";

const Whatsapp = () => {
  return (
    <div className="fixed bottom-[40px] right-[40px] z-10">
      <a href="https://wa.me/+0528872587" target="__blank">
        <img
          src={
            "https://yde-files.s3.eu-central-1.amazonaws.com/static/whatsapp.webp"
          }
          alt="Whatsapp Icon"
          style={{
            width: "60px",
            height: "60px",
          }}
        />
      </a>
    </div>
  );
};

export default Whatsapp;
