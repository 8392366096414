import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/offices`;

const getOffice = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const officeService = {
  getOffice,
};

export default officeService;
