import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/insights`;

const getAllTags = async () => {
  const response = await axios.get(API_URL + "/tags");
  return response.data;
};

const getInsights = async (page) => {
  const response = await axios.get(API_URL + `?page=${page}`);
  return response.data;
};

const getInsight = async (slug) => {
  const response = await axios.get(API_URL + `/${slug}`);
  return response.data;
};

const getInsightByTags = async (id, page) => {
  const response = await axios.get(API_URL + `/tags/${id}?page=${page}`);
  return response.data;
};

const getOldestInsight = async (page) => {
  const response = await axios.get(API_URL + `/oldest?page=${page}`);
  return response.data;
};

const getInsightByAlpha = async (page) => {
  const response = await axios.get(API_URL + `/alpha?page=${page}`);
  return response.data;
};

const getInsightsBySearch = async (search, page) => {
  const response = await axios.post(
    API_URL + `/search?q=${search}&page=${page}`
  );
  return response.data;
};

const insightService = {
  getAllTags,
  getInsights,
  getInsight,
  getInsightByTags,
  getOldestInsight,
  getInsightByAlpha,
  getInsightsBySearch,
};

export default insightService;
