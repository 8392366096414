import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/solutions`;

const getBenefits = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const solutionService = {
  getBenefits,
};

export default solutionService;
