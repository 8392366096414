import React from "react";
// import Video from '../assets/images/banner.mp4';
import Logo from "../assets/images/homescreen/logo.webp";

const ComingSoon = ({ handleSubmit, handleChange }) => {
  return (
    <>
      <div className="fullscreen">
        {/* <video className='video-bg' autoPlay muted loop>
          <source src={Video} type='video/mp4' />
        </video> */}

        <div className="w-[500px] h-[400px] absolute top-[100px] left-[50%] transform -translate-x-[50%] bg-[#ffffff] rounded-lg">
          <div className="flex justify-center items-center h-full">
            <div className="flex flex-col text-center">
              {/* <div className='text-secondary-500 font-gotham-black text-4xl'>Coming Soon</div> */}
              <div>
                <img
                  loading="lazy"
                  className="w-25 h-25 object-contain mt-3 inline-block"
                  src={Logo}
                  alt="Yellow Door Energy"
                />
              </div>
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col space-y-4">
                    <input
                      className="border-2 border-secondary-500 rounded-xl outline-none text-primary-500 p-2 mt-4"
                      type="text"
                      name="username"
                      placeholder="username"
                      onChange={handleChange}
                    />
                    <input
                      className="border-2 border-secondary-500 rounded-xl outline-none text-primary-500 p-2"
                      type="password"
                      name="password"
                      placeholder="password"
                      onChange={handleChange}
                    />
                    <button
                      type="submit"
                      className="bg-primary-500 text-[#ffffff] py-3 rounded-md]"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
