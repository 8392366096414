import axios from "axios";
import config from "../../config";

const API_URL = `${config.apiUrl}/api/projects`;

const getProjects = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const getProject = async (slug) => {
  const response = await axios.get(API_URL + `/${slug}`);
  return response.data;
};

const getProjectByMarket = async (market_id) => {
  const response = await axios.get(API_URL + `/project/${market_id}`);
  return response.data;
};

const getOldestProject = async () => {
  const response = await axios.get(API_URL + `/oldest`);
  return response.data;
};

const getProjectByAlpha = async () => {
  const response = await axios.get(API_URL + `/alpha`);
  return response.data;
};

const getProjectsBySearch = async (search) => {
  const response = await axios.post(API_URL + `/search?q=${search}`);
  return response.data;
};

const getBenefits = async (id) => {
  const response = await axios.get(API_URL + `/benefits/${id}`);
  return response.data.benefits;
};

const getDescription = async (id) => {
  const response = await axios.get(API_URL + `/benefits/${id}`);
  return response.data.descriptions;
};

const projectService = {
  getProjects,
  getProject,
  getBenefits,
  getDescription,
  getProjectByMarket,
  getOldestProject,
  getProjectByAlpha,
  getProjectsBySearch,
};

export default projectService;
